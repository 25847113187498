.parallax {
    position: relative;
    overflow: hidden;
  }
  
  .scroller {
    display: flex;
    white-space: nowrap;
    text-align: center;
    font-family: sans-serif;
    font-size: 2.25rem;
    font-weight: bold;
    letter-spacing: -0.02em;
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.1));
  }
  
  .scroller span {
    flex-shrink: 0;
  }
  
  @media (min-width: 768px) {
    .scroller {
      font-size: 5rem;
      line-height: 5rem;
    }
  }
.pixelated-image-card {
    background-color: #222;
    color: var(--color-primary, #fff);
    border-radius: 15px;
    border: 2px solid #fff;
    /* width: 300px; */
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .pixelated-image-card__default,
  .pixelated-image-card__active,
  .pixelated-image-card__pixels {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .pixelated-image-card__active {
    z-index: 2;
  }
  
  .pixelated-image-card__active {
    display: none;
  }
  
  .pixelated-image-card__pixels {
    pointer-events: none;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .pixelated-image-card__pixel {
    display: none;
    position: absolute;
  }
  
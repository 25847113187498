body {
  font-family: 'Plus Jakarta Sans', sans-serif; /* Default font */
}
/* * {
  cursor: default !important;
} */
.no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

/* Navigation Bar Styles */
.navigation-bar {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Drop shadow at the bottom */
}

/* Flex container adjustments for upper and lower navbar */
.upper-navbar,
.lower-navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

/* Default mobile-friendly styles */
.upper-navbar .left-part,
.upper-navbar .middle-part,
.upper-navbar .right-part,
.lower-navbar .left-part,
.lower-navbar .middle-part,
.lower-navbar .right-part {
  flex: 1 1 100%;
  text-align: center;
  margin: 5px 0;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .upper-navbar,
  .lower-navbar {
    flex-wrap: nowrap;
  }

  .upper-navbar .left-part,
  .upper-navbar .middle-part,
  .upper-navbar .right-part,
  .lower-navbar .left-part,
  .lower-navbar .middle-part,
  .lower-navbar .right-part {
    flex: initial;
    text-align: left;
    margin: 0;
  }

  .lower-navbar .middle-part {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}
/* src/App.css */

/* Show subcategory dropdown on hover */
.lower-navbar .middle-part .subcategory-dropdown {
  display: none;
}

.lower-navbar .middle-part:hover .subcategory-dropdown {
  display: block;
}

/* Optional: Adjust z-index to ensure dropdown appears above other elements */
.subcategory-dropdown {
  z-index: 1000;
}
/* src/App.css */

/* Banner grid layout */
.banner-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "banner1 banner2"
    "banner1 banner3"
    "banner4 banner4";
  gap: 20px;
}

/* Position each banner */
.banner1 {
  grid-area: banner1;
}
.banner2 {
  grid-area: banner2;
}
.banner3 {
  grid-area: banner3;
}
.banner4 {
  grid-area: banner4;
}

/* Hover effect (if not handled inline) */
.banner-grid .banner:hover {
  transform: scale(1.03);
  transition: transform 0.3s;
}

/* Mobile: stack banners */
@media (max-width: 768px) {
  .banner-grid {
    grid-template-columns: 1fr;
    grid-template-areas:
      "banner1"
      "banner2"
      "banner3"
      "banner4";
  }
}
/* Full-width dark container for the category section */
.full-width-dark-container {
  width: 100%;
  background-color: #000;
  padding: 50px 0;
}

/* Inner content container for proper padding */
.category-content {
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

/* Grid layout for categories */
.category-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

/* Responsive grid adjustments */
@media (max-width: 1200px) {
  .category-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .category-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .category-grid {
    grid-template-columns: 1fr;
  }
}

/* Responsive title styles */
.responsive-title {
  font-family: "Lora", serif;
  font-weight: 600;
  font-size: 3rem;
  color: #fff;
}

@media (max-width: 768px) {
  .responsive-title {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .responsive-title {
    font-size: 2rem;
  }
}

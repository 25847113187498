.squares-canvas {
    width: 100%;
    height: 100%;
    border: none;
    display: block;
  }
  .squares-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  